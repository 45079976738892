import React from 'react'
import { Link } from "gatsby"
import Layout from '../Layouts/zh'
import { kotiTitleGenerator } from '../utils'
import SEO from '../components/SEO'

import './terms.scss'


const TermsPage = ({ location }) => (
  <Layout location={location}>
  <SEO
      title={kotiTitleGenerator('私隱政策')}
      description="Delight Labs 致力於保護和尊重您的私隱。"
    />
    <div className="page-terms">
      <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>條款及細則</h1>
            <h4>
              Delight Labs 致力於保護和尊重您的私隱。
            </h4>
            <hr />
          </div>
        </div>
      </div>
    </section>
      <section>
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8">
            <article>
              <span className="h4">上次更新時間：2019/10/10</span>
              <p>
                <h5>
                  在本政策中：
                </h5>
                <p>
                  FY Innovation Group Limited （以下簡稱「 Delight Labs」）保留在不另行通知的情況下增加、修改、更改、暫停或永久終止本網站全部或任何部分以及限製或禁止訪問它。
                </p>
                <p>
                  「個人信息」是指您與可識別個人相關的，由您提供給我們的，或通過您對我們的產品或服務的使用提供的任何數據，信息，或數據與信息的組合； 「平台」是指我們的網站（https://delight.global）； 「Delight Labs」或「我們」的定義在我們的服務條款中。
                </p>
              </p>
              <p className="type--bold">
                1. 我們收集有關您的哪些信息
              </p>
              <h5>
                1.1. 我們收集有關您以下類別的信息：
              </h5>
              <p>
                a) 您在註冊帳戶或註冊我們的產品或服務時提供的帳戶和個人資料信息，例如您的姓名，電子郵件地址，實際地址和電話號碼（統稱「帳戶數據」）；
              </p>
              <p>
                b) 您通過我們的支持渠道提供的信息，例如，當您向我們報告問題或與我們的支持團隊互動時，包括任何联系信息，文檔，圖像或屏幕截圖（統稱為「支持數據」）；
              </p>
              <p>
                c) 您通過使用我們的產品或服務提供的內容，例如您在我們的平台中創建的文檔中包含的個人信息（統稱為「用戶內容」）；
              </p>
              <p>
                d) 在設置帳戶或個人資料時，或者在您參加我們發送給您的調查或問卷時（您統稱為「偏好數據」），您設置的溝通，營銷和其他偏好；
              </p>
              <p>
                e) 您與我們進行的任何交易，購買或訂單的詳細信息（統稱為「交易數據」）；
              </p>
              <p>
                f) 付款信息，例如您的付款卡信息或銀行帳戶詳細信息（統稱為「財務數據」）；
              </p>
              <p>
                g) 有關設備或連接的信息，例如您的互聯網協議（IP）地址，登錄數據，瀏覽器類型和版本，時區設置，瀏覽器插件類型和版本，操作系統和平台以及設備上的其他技術您用於訪問我們通過cookie和其他數據收集技術收集的產品或服務以及信息（請閱讀我們的Cookies政策以了解詳細信息）（統稱為「技術數據」）；和
              </p>
              <p>
                h) 有關您使用或訪問我們平台的信息，例如您查看，使用或搜索的產品的點擊流，通過我們的平台的點擊流，下載或其他錯誤，頁面響應時間，訪問時間某些頁面，頁面交互信息（例如，滾動，單擊和鼠標懸停），或從頁面上瀏覽的方法（統稱為「使用數據」）。
              </p>
              <h5>
                1.2. 當您提供給我們或您使用或訪問我們的平台時，我們會收集上述信息。我們還可能從其他來源接收有關您的信息，包括：
              </h5>
              <p>
                a) 與我們的運營或服務有關的我們的人員、代理商和顧問，例如：我們的工作人員正在履行您的訂單，處理您的付款以及提供支持服務；
              </p>
              <p>
                b) 我們產品或服務的其他用戶，例如，當您的組織成員在我們的平台上為每個團隊成員註冊一個帳戶時；
              </p>
              <p>
                c) 與您的帳戶關聯的其他服務，例如，如果您使用Google憑據註冊或登錄帳戶，我們會在您的Google個人資料設置允許的情況下收到您的姓名和電子郵件地址，以對您進行身份驗證；
              </p>
              <p>
                d) 我們提供信息技術，系統管理和/或營銷服務的集團公司或海外辦事處；和
              </p>
              <p>
                e) 我們的業務夥伴和服務供應商誰提供技術，支付，送貨服務，廣告網絡，數據分析，市場調研，和搜索信息服務。
              </p>
              <p>
                如果您希望購買通過服務提供的任何產品或服務（以下簡稱「購買」），則可能會要求您提供與購買有關的某些信息，包括但不限於
              </p>
              <h5>
                1.3 . 我們不會收集有關您的敏感數據或特殊類別數據。這包括有關您的種族，族裔，政治，宗教，工會會員資格，遺傳學，生物特徵識別，健康或性取向的詳細信息。
              </h5>
              <p className="type--bold">2. 我們如何使用收集的信息</p>
              <h5>
                2.1. 我們僅在法律允許的範圍內使用您的個人信息。我們僅在以下情況下使用您的個人信息：
              </h5>
              <p>
                a) 我們需要履行與您簽訂（或將要簽訂的合同）的合同，包括操作我們的產品或服務，提供客戶支持和個性化功能以及保護我們平台的安全性；
              </p>
              <p>
                b) 滿足您的基本權利或數據保護利益所不能超越的合法利益，例如，為了改進我們的產品或服務而進行的研究和開發，以及為了保護我們的合法權利和利益；
              </p>
              <p>
                c) 您已同意我們出於特定目的這樣做，例如，在您的允許下，我們可能會向您發送直接營銷材料或發布您的信息，作為我們的推薦或客戶故事的一部分，以推廣我們的產品或服務；或
              </p>
              <p>d) 我們需要遵守法律或監管義務。</p>
              <h5>
                2.2. 如果您已同意我們將您的個人信息用於特定目的，則您有權隨時通過與我們聯繫來撤回您的同意，但是請注意，這不會影響對您已經使用的信息的任何使用。
              </h5>
              <h5>
                2.3. 我們不會出於營銷目的與我們集團以外的任何公司共享您的個人信息，除非獲得您的明確明確同意。
              </h5>
              <p className="type--bold">
                3. 我們如何共享我們收集的信息
              </p>
              <h5>
                3.1. 我們與集團中的其他公司共享信息，以操作我們的平台並提供和改善我們的產品和服務。
              </h5>
              <h5>
                3.2. 我們與幫助我們運營、提供、支持、 改進和銷售我們產品和服務的第三方共享信息，例如，提供雲端服務、保養、備份、儲存、架構建設、寄發帳單、付款處理、客戶的第三方服務供應商，業務分析及其他服務等。
              </h5>
              <h5>
                3.3. 第三方服務提供商只能出於執行服務的目的並遵守適用的法律法規來訪問您的個人信息。我們要求這些第三方服務提供商維護代表我們處理的所有個人信息的機密性和安全性，並實施和維護合理的安全措施以保護您的個人信息的機密性，完整性和可用性。
              </h5>
              <h5>
                3.4. 我們採取合理的步驟來確認我們所聘用的所有第三方服務供應商，都以至少提供與本政策相同的保護級別來處理個人信息。如果任何第三方提供商不能滿足我們的要求，我們將要求他們立即通知我們，並且我們將採取合理的措施來防止或停止不合規的處理。
              </h5>
              <h5>
                3.5. 如果您使用與我們的產品或服務有關的任何第三方軟件，例如與我們的平台集成的任何第三方軟件，則可以向第三方軟件提供商授予訪問您的帳戶和信息的權限。第三方軟件提供商的政策和程序不受我們的控制，並且本政策不涵蓋第三方軟件提供商如何收集或使用您的信息。我們建議您在使用第三方軟件之前先閱讀第三方軟件提供商的隱私政策。
              </h5>
              <h5>
                3.6. 我們的平台可能包含指向我們無法控制的第三方網站的鏈接。如果您訪問這些網站的鏈接或向其提交信息，則您的信息將受其政策約束。我們建議您在向第三方網站提交信息之前先閱讀其隱私政策。
              </h5>
              <h5>
                3.7. 我們可能會與政府和執法人員共享您的信息，以遵守適用的法律或法規，例如，當我們響應索賠，法律程序，執法或國家安全要求時。
              </h5>
              <h5>
                3.8. 如果我們是由於合併，收購或業務轉讓而被第三方收購，則您的個人信息可能會因該交易而被披露和/或轉讓給第三方。如果發生此類交易，我們將通知您，並告知您關於信息的任何選擇。
              </h5>
              <p className="type--bold">
                4. 我們如何存儲和保護我們收集的信息
              </p>
              <h5>
                4.1. 我們使用雲服務提供商託管我們收集的信息，以使我們能夠作為全球平台運行。
              </h5>
              <h5>
                4.2. 我們採取了以下措施來保護您的個人信息的安全性和完整性：
              </h5>
              <p>
                a) 您在我們的平台中創建，存儲和電子簽名的任何文檔均以加密形式存儲在磁盤上，並通過TLS / SSL加密安全傳輸；任何付款交易均使用TLS / SSL技術加密；
              </p>
              <p>
                b) 僅在完全需要了解的情況下，人員或服務提供商才能訪問您的個人信息，他們將僅按照我們的指示處理您的信息，並承擔保密責任；和
              </p>
              <p>
                c) 我們的信息採集，存儲，加工實踐定期審查。
              </p>
              <h5>
                4.3. 我們已制定程序，以處理任何可疑的隱私違規行為，並將在法律要求的情況下，通知您以及任何適用的監管機構。
              </h5>
              <h5>
                4.4. 儘管我們實施旨在保護您的信息的安全措施，但請注意，在互聯網上傳輸信息不是絕對安全的。
              </h5>
              <h5>
                4.5. 我們不能保證您的信息在通過互聯網傳輸或儲存在我們的系統中，或由我們處理的過程中絕對安全。我們僅在合理和必要的情況下保留個人信息，以實現我們收集信息的目的，包括出於滿足任何法律，會計或報告要求的目的。
              </h5>
              <h5>
                4.6. 在這段時間之後，我們將刪除或匿名化您的信息，或者，如果無法做到，我們將安全地存儲您的信息，並使其不再使用。我們會定期審查數據保留政策的基礎和適當性。
              </h5>

              <p className="type--bold">
                5. 我們如何在國際範圍內傳輸信息
              </p>
              <h5>
                5.1. 我們在全球範圍內收集信息。我們會將您的信息轉移，處理和存儲在您或我們或我們的服務提供商所在的居住國以外的地區，目的是向您提供我們的產品和服務。
              </h5>

              <h5>
                5.2. 公司或服務提供商所在的某些國家/地區可能沒有與您所居住國家/地區相同的隱私和數據保護法律。當我們與這些公司或服務提供商共享信息時，我們會利用合同條款，公司規則和其他適當的機制來保護信息的傳輸。
              </h5>

              <p className="type--bold">6. 您的權利</p>
              <h5>6.1. 您有權：</h5>
              <p>
                a) 被告知我們如何處理您的個人信息；
              </p>
              <p>
                b) 索取我們持有的有關您的個人信息的副本；
              </p>
              <p>
                c) 要求我們糾正我們持有的有關您的任何個人信息中的任何不准確性或錯誤；
              </p>
              <p>
                d) 請求刪除您的個人信息（但是，請注意，如果出於我們最初收集該信息的目的，出於記錄保存或法律目的仍需要該個人信息，我們可能無法始終符合您的刪除要求合規目的）；
              </p>
              <p>
                e) 反對或限制我們處理您的個人信息（包括出於營銷目的）；
              </p>
              <p>
                f) 請求以結構化，常用且機器可讀的格式接收您的某些個人信息，並請求我們將此類信息轉移給另一方；和
              </p>
              <p>
                g) 在這裡，我們是靠同意將您的個人信息（雖然這不會影響進行了您撤回同意）之前的任何處理的合法性任何時候撤銷同意。
              </p>

              <h5>
                6.2. 您可以通過使用我們通訊中的退訂鏈接或與我們聯繫來選擇不接收我們的營銷材料。但是請注意，即使您選擇不接收我們的營銷材料，您仍將繼續收到我們使用產品或服務所必需的通知或信息。
              </h5>

              <h5>
                6.3. 作為安全措施，我們可能需要您的特定信息來幫助我們在處理您的隱私請求或行使權利時確認您的身份。
              </h5>
              <h5>
                6.4. 根據第6.1款提出的任何請求通常都會免費處理。但是，如果您的請求明顯沒有根據，重複或過多，我們可能會收取合理的管理費。
              </h5>

              <h5 className="type--bold">7. 對本政策的更改</h5>
              <p>
                我們可能會不時通過在平台上發布更新的政策來修改本政策。更改生效後繼續使用我們的平台，即表示您同意受修訂後的政策的約束。
              </p>

              <h5 className="type--bold">8. 對兒童的政策</h5>
              <p>
                我們的產品和服務不針對16歲以下的個人。我們不會有意地從16歲以下的個人那裡收集個人信息。如果我們意識到16歲以下的個人為我們提供了個人信息，我們將採取以下措施：刪除此類信息。如果您認為我們錯誤地或無意地從16歲以下的個人那裡收集了信息，請與我們聯繫。
              </p>

              <h5 className="type--bold">9. Cookies政策</h5>
              <p>
                Cookies是當您訪問我們的平台時，由Web服務器放置在設備上的小型文本文件。我們使用Cookie來識別您的訪問權限，並在平台上監控使用情況和網絡流量，以自定義和改進我們的產品和服務。我們同時使用持久性Cookie和會話Cookie。永久cookie保留在您的瀏覽器中，當您返回我們的站點或使用我們服務的合作夥伴站點時，它將被我們讀取。會話Cookie只會持續到會話持續的時間（通常是當前對網站或瀏覽器會話的訪問）。
              </p>

              <p>我們使用以下類型的cookie：</p>
              <p>
                a) 非常必要的cookie –這些是我們網站運營所必需的cookie。例如，它們包括cookie，使您能夠登錄到我們網站的安全區域。
              </p>
              <p>
                b) 分析/性能cookie –這些cookie使我們能夠識別和統計訪問者的數量，並查看訪問者在使用我們網站時如何在網站上四處走動。例如，通過確保用戶可以輕鬆找到所需的內容，這有助於我們改善網站的工作方式。
              </p>
              <p>
                c) 功能cookie –當您返回我們的網站時，這些cookie用來識別您。
              </p>
              <p>
                d) 定位cookie –這些cookie記錄您對我們網站的訪問，訪問過的頁面以及您所關注的鏈接。
              </p>
              <p>
                您可以通過激活瀏覽器上的設置來阻止cookie，該設置允許您拒絕使用全部或部分cookie。但是，如果這樣做，則可能無法訪問我們網站的全部或部分內容。
              </p>
              <p>*中文版本為翻譯本，一切以英文版本為準。</p>

              <hr />
              <h5>聯繫我們</h5>
              <p>
                如果您對這些條款有任何疑問，請與我們<Link to="/zh-hk/contact">聯繫</Link>。
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
    </div>
    {/* <button
      className="back-to-top inner-link active"
      onClick={() => animateScroll.scrollToTop()}
    >
      <i className="stack-interface stack-up-open-big" />
    </button> */}
    {/* <CallToAction_01/> */}
  </Layout>
)

export default TermsPage
